export default class Editable {
  constructor(element) {
    this.element = $(element);
    this.modalId = this.setModalId();
    this.url = this.element.data("url");

    this.messageBox = "[data-behavior='editable-message']";

    this.element.on("click", (event) => {
      event.preventDefault();

      this.show();
    });

    $("body").on("hidden.bs.modal", this.modalId, () => {
      $(this.modalId).remove();

      if (this.element.data("reload")) {
        location.reload();
      }
    });

    $("body").on("ajax:success", this.modalId, (xhr) => {
      let response = this.asJson(xhr.detail[0]);

      if (response.message) {
        this.displayMessage(response.message, response.type);
        $(this.modalId).find(":submit").prop("disabled", false);
      } else {
        this.updateContent(response.html);
      }
    });

    $("body").on("ajax:send", this.modalId, () => {
      $(this.modalId).find(":submit").prop("disabled", true);
    });
  }

  asJson(string) {
    try {
      return JSON.parse(string);
    } catch (e) {
      return string;
    }
  }

  displayMessage(message, type) {
    $(this.messageBox).addClass("--" + type);
    $(this.messageBox).html(message).show();
  }

  params() {
    let params = {};

    $.each(this.element.data(), (key, value) => {
      if (key.startsWith("param")) {
        let param = key.replace("param", "").toLowerCase();

        params[param] = value;
      }
    });

    if (this.element.data("namespace")) {
      return {
        [this.element.data("namespace")]: params,
      };
    } else {
      return params;
    }
  }

  setModalId() {
    let modalId;

    if (this.element.data("modal-id")) {
      modalId = this.element.data("modal-id");
    } else {
      modalId = this.element.data("behavior");
    }

    return "#" + modalId;
  }

  show() {
    $.ajax({
      url: this.url,
      type: "GET",
      data: this.params(),
      success: (data) => {
        $(data.html).appendTo("body");
        $(this.modalId).modal();
      },
    });
  }

  updateContent(html) {
    let target = $(this.modalId).find(
      "[data-behavior='editable-replace-target']"
    );

    if (target) {
      target.html(html);
    }

    $(this.messageBox).html("").hide();
  }
}

$(document).on("turbolinks:load", () => {
  $("[data-behavior='editable']").each(function () {
    new Editable(this);
  });
});
