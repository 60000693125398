import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";

$(document).on("turbolinks:load", () => {
  let picker = "[data-behavior='date-picker']";

  flatpickr.localize(Spanish);
  flatpickr(picker, {
    mode: "range",
    wrap: "true",
    dateFormat: "j \\de M, Y",
    defaultDate: [$(picker).data("start"), $(picker).data("end")],
    onClose: function (selectedDates) {
      let url = $(picker).data("url");
      let startDate = flatpickr.formatDate(selectedDates[0], "Y-m-d");
      let endDate = flatpickr.formatDate(selectedDates[1], "Y-m-d");
      let period = startDate + "," + endDate;

      window.location = url + "?period=" + period;
    },
  });
});
