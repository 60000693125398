$(document).on("change", "[data-behavior='plan-update']", function () {
  let option = $(this).children("option:selected");

  updatePaymentData(option.data());
});

$(document).on("change", "[data-behavior='method-update']", function () {
  updatePaymentAmount();
});

$(document).on("change", "[data-behavior='branch-update']", function () {
  let option = $(this).children("option:selected");

  updatePlanOptions(option.data());
});

function updatePlanOptions(branch) {
  if (branch.behavior == "plan") {
    $.ajax({
      url: "/admin/branches/" + branch.slug + "/plans",
      type: "GET",
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      success: (data) => {
        let select = $("[data-behavior='plan-update']");
        select.empty();

        $(data).each(function () {
          $("<option/>", {
            text: this.name,
            value: this.id,
            data: {
              price: this.price,
              description: this.description,
              classes: this.classes === 0 ? "Ilimitadas" : this.classes,
            },
          }).appendTo(select);
        });

        updatePaymentData(data[0]);
      },
    });
  } else {
    updatePaymentData(branch);
  }
}

function updatePaymentData(data) {
  if (data.classes) $("#payment_total_classes").val(data.classes);
  if (data.description) $("#payment_concept").val(data.description);
  if (data.price) $("#payment_net_amount").val(data.price);

  updatePaymentAmount();
}

function updatePaymentAmount() {
  let method = $("[data-behavior='method-update']").children("option:selected");
  let amount = parseInt($("#payment_net_amount").val());
  let fee = parseFloat(method.data("fee"));
  let tax = parseFloat(method.data("tax"));
  let total = amount / (1 - (fee * (1 + tax)) / 100);

  $("#payment_amount").val(total.toFixed(2));
}
