$(document).on("click", "[data-behavior='swap-user']", function () {
  let active = $(this).siblings("[data-behavior='swap-user-on']").first();
  let inactive = $(this).siblings("[data-behavior='swap-user-off']").first();

  active
    .attr("data-behavior", "swap-user-off")
    .prop("required", false)
    .prop("disabled", true)
    .hide();

  inactive
    .attr("data-behavior", "swap-user-on")
    .prop("required", true)
    .prop("disabled", false)
    .show();
});

$(document).on("click", "[data-behavior='edit-appointment']", function () {
  $.ajax({
    url: $(this).data("url"),
    type: "GET",
    success: (data) => {
      $(this).closest("td").html(data.html);
    },
  });
});
