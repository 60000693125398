export default class Dismissable {
  constructor(element) {
    this.element = $(element);
    this.dismiss();
  }

  dismiss() {
    setTimeout(
      function () {
        this.element.slideUp();
      }.bind(this),
      3000
    );
  }
}

$(document).on("turbolinks:load", () => {
  $("[data-behavior='dismissable']").each(function () {
    new Dismissable(this);
  });
});
