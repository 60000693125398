export default class ClassSchedule {
  constructor(element) {
    this.element = $(element);
    this.id = this.element.data("id");
    this.token = $('meta[name="csrf-token"]').attr("content");

    $(this.element).on("click", () => {
      this.previousSelection = this.element.children("option:selected").val();
    });

    $(this.element).on("change", () => {
      this.instructorId = this.element.children("option:selected").val();
      this.request();
    });
  }

  create() {
    $.ajax({
      url: "/admin/class_schedules",
      type: "POST",
      data: {
        instructor_id: this.instructorId,
        branch_id: this.element.data("branch-id"),
        weekday: this.element.data("weekday"),
        time: this.element.data("time"),
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader("X-CSRF-Token", this.token);
      },
      success: (data) => {
        if (data.error) {
          this.displayMessage(data.html);
        } else {
          this.id = data.id;
          this.element.closest("td").removeClass("--unavailable");
        }
      },
    });
  }

  delete() {
    $.ajax({
      url: "/admin/class_schedules/" + this.id,
      type: "DELETE",
      beforeSend: (xhr) => {
        xhr.setRequestHeader("X-CSRF-Token", this.token);
      },
      success: () => {
        this.id = null;
        this.element.closest("td").addClass("--unavailable");
      },
    });
  }

  displayMessage(html) {
    $("[data-behavior='class-schedule-error']").remove();
    $(html).insertBefore(this.element.closest("table")).delay(3000).slideUp();

    this.element.prop("selectedIndex", this.previousSelection);
  }

  request() {
    if (this.instructorId) {
      if (this.id) {
        this.update();
      } else {
        this.create();
      }
    } else {
      this.delete();
    }
  }

  update() {
    $.ajax({
      url: "/admin/class_schedules/" + this.id,
      type: "PATCH",
      data: { instructor_id: this.instructorId },
      beforeSend: (xhr) => {
        xhr.setRequestHeader("X-CSRF-Token", this.token);
      },
      success: (data) => {
        if (data.error) {
          this.displayMessage(data.html);
        }
      },
    });
  }
}

$(document).on("turbolinks:load", () => {
  $("[data-behavior='class-schedule']").each(function () {
    new ClassSchedule(this);
  });
});
